import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _32b59af8 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _8e7a111e = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _68004bf1 = () => interopDefault(import('..\\pages\\have-a-card\\index.vue' /* webpackChunkName: "pages_have-a-card_index" */))
const _e763a028 = () => interopDefault(import('..\\pages\\have-a-card\\eligibility.vue' /* webpackChunkName: "pages_have-a-card_eligibility" */))
const _798da675 = () => interopDefault(import('..\\pages\\have-a-card\\enrollment.vue' /* webpackChunkName: "pages_have-a-card_enrollment" */))
const _ea53377a = () => interopDefault(import('..\\pages\\have-a-card\\patient-information.vue' /* webpackChunkName: "pages_have-a-card_patient-information" */))
const _0c651ce2 = () => interopDefault(import('..\\pages\\have-a-card\\success.vue' /* webpackChunkName: "pages_have-a-card_success" */))
const _4656ff0e = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _dc437ef8 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _cf514c54 = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _2e10b799 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _2e1ecf1a = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _2e3afe1c = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _2e49159d = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _62f6f2da = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _72d9cd1a = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _1c207b27 = () => interopDefault(import('..\\pages\\need-a-card\\insurance.vue' /* webpackChunkName: "pages_need-a-card_insurance" */))
const _39edc7f4 = () => interopDefault(import('..\\pages\\need-a-card\\insurance-information.vue' /* webpackChunkName: "pages_need-a-card_insurance-information" */))
const _52c0f371 = () => interopDefault(import('..\\pages\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_need-a-card_patient-information" */))
const _2c96aa07 = () => interopDefault(import('..\\pages\\need-a-card\\prescriber-information.vue' /* webpackChunkName: "pages_need-a-card_prescriber-information" */))
const _59d4293a = () => interopDefault(import('..\\pages\\need-a-card\\select-path.vue' /* webpackChunkName: "pages_need-a-card_select-path" */))
const _21ba8110 = () => interopDefault(import('..\\pages\\need-a-card\\success.vue' /* webpackChunkName: "pages_need-a-card_success" */))
const _72b3f328 = () => interopDefault(import('..\\pages\\patient\\patientSearch.vue' /* webpackChunkName: "pages_patient_patientSearch" */))
const _2221067c = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _32b59af8,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _8e7a111e,
    children: [{
      path: "",
      component: _68004bf1,
      name: "have-a-card"
    }, {
      path: "eligibility",
      component: _e763a028,
      name: "have-a-card-eligibility"
    }, {
      path: "enrollment",
      component: _798da675,
      name: "have-a-card-enrollment"
    }, {
      path: "patient-information",
      component: _ea53377a,
      name: "have-a-card-patient-information"
    }, {
      path: "success",
      component: _0c651ce2,
      name: "have-a-card-success"
    }]
  }, {
    path: "/rejection",
    component: _4656ff0e,
    name: "rejection"
  }, {
    path: "/unlock",
    component: _dc437ef8,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _cf514c54,
    name: "upload-documents"
  }, {
    path: "/errors/400",
    component: _2e10b799,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _2e1ecf1a,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _2e3afe1c,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _2e49159d,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _62f6f2da,
    name: "errors-500"
  }, {
    path: "/need-a-card/eligibility",
    component: _72d9cd1a,
    name: "need-a-card-eligibility"
  }, {
    path: "/need-a-card/insurance",
    component: _1c207b27,
    name: "need-a-card-insurance"
  }, {
    path: "/need-a-card/insurance-information",
    component: _39edc7f4,
    name: "need-a-card-insurance-information"
  }, {
    path: "/need-a-card/patient-information",
    component: _52c0f371,
    name: "need-a-card-patient-information"
  }, {
    path: "/need-a-card/prescriber-information",
    component: _2c96aa07,
    name: "need-a-card-prescriber-information"
  }, {
    path: "/need-a-card/select-path",
    component: _59d4293a,
    name: "need-a-card-select-path"
  }, {
    path: "/need-a-card/success",
    component: _21ba8110,
    name: "need-a-card-success"
  }, {
    path: "/patient/patientSearch",
    component: _72b3f328,
    name: "patient-patientSearch"
  }, {
    path: "/",
    component: _2221067c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
